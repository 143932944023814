
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import AppConfig from "@/config/AppConfig";
import UserForm from "@/views/Maintenance/User/UserForm.vue";

const UserModule = namespace(AppConfig.USER_STORE);

@Component({
  components: { UserForm },
  mixins: [CreateMixin],
})
export default class UserCreate extends Vue {
  protected name = "UserCreate.vue";

  protected get resource(): string {
    return "/user";
  }

  protected get descriptionField(): string {
    return "email";
  }

  @UserModule.Action("create")
  protected createItemAction: any;

  protected redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "UserUpdate",
      params: { id: responseData.id },
    });
  }
}
